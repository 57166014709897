<template>
  <div>
    <ion-menu side="start" content-id="main-content">
      <ion-header>
        <ion-toolbar translucent>
          <ion-title>Menu {{ state.layout }} </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item>
            <ion-icon name="mail" slot="start"></ion-icon>
            <ion-label>Inbox</ion-label>
          </ion-item>
          <ion-item>
            <ion-icon name="paper-plane" slot="start"></ion-icon>
            <ion-label>Outbox</ion-label>
          </ion-item>
          <ion-item>
            <ion-icon name="heart" slot="start"></ion-icon>
            <ion-label>Favorites</ion-label>
          </ion-item>
          <ion-item>
            <ion-icon name="archive" slot="start"></ion-icon>
            <ion-label>Archived</ion-label>
          </ion-item>
          <ion-item>
            <ion-icon name="trash" slot="start"></ion-icon>
            <ion-label>Trash</ion-label>
          </ion-item>
          <ion-item>
            <ion-icon name="warning" slot="start"></ion-icon>
            <ion-label>Spam</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>

    <div class="ion-page" id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-title>Inbox {{ state.layout }} </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-router-outlet/>
      </ion-content>
    </div>
  </div>
</template>
<style>
.my-custom-menu {
  --width: 500px;
}
</style>

<script>
import { 
  IonRouterOutlet, 
  IonHeader, 
  IonItem, 
  IonList, 
  IonMenu, 
  IonTitle, 
  IonToolbar,
  // mentController,
  IonIcon,
  IonLabel,
  IonContent,
  IonMenuButton,
  IonButtons
} from '@ionic/vue';
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  components: {
    IonRouterOutlet, 
    IonHeader, 
    IonItem, 
    IonList, 
    IonMenu,
    IonTitle, 
    IonToolbar,
    IonIcon,
    IonLabel,
    IonContent,
    IonMenuButton,
    IonButtons
  },
  // setup (props, { emit }) {
  setup () {
    const state = reactive({
      layout: ""
    })
    return {
      state
    }
  }
});
</script>